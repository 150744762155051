import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from '@react-hookz/web';

import { screen } from '@/components/common/breakpoints';
import { Divider } from '@/components/common/Divider';
import { Paragraph } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import { Col, Row } from '@/components/layout/Grid';
import { Section } from '@/components/layout/Section';
import { Stack } from '@/components/layout/Stack';

import type { Sizes } from '@/components/layout/Grid';
import type { SurfaceColourTokenType } from '@/types/colours';
import type { SanityStatsType } from '@/types/sanity';

export interface StatsProps extends Omit<SanityStatsType, '_type'> {
  sizes?: Sizes;
}

export const StatsComponent = ({ statistics, sizes }: StatsProps) => {
  const windowSize = useMediaQuery(screen.lgQuery);
  const [isMobile, setIsMobile] = useState<boolean | null>(null);

  useEffect(() => {
    setIsMobile(windowSize);
  }, [windowSize]);

  return (
    <Row>
      <Col
        sizes={sizes || { base: 4, md: statistics.length < 3 ? 8 : 10 }}
        start={{ md: statistics.length < 3 ? 3 : 2 }}
      >
        <StatContainer statColor={'--surface-inverse-base'}>
          {statistics.map((stat, index) => {
            return (
              <>
                <Surface
                  borderRadius="--radius-m"
                  surfaceColour={'--surface-inverse-base'}
                  padding="--space-component-xxl"
                >
                  <Stack spacing="--space-component-sm">
                    <Paragraph
                      size="heading-lg"
                      style={{ color: ' var(--text-eclipse)' }}
                    >
                      {stat.figure}
                    </Paragraph>
                    <BoldParagraph
                      size="subheading-sm"
                      style={{ color: 'var(--text-eclipse)' }}
                    >
                      {stat.label}
                    </BoldParagraph>
                    {stat?.description && (
                      <Paragraph
                        size="subheading-sm"
                        style={{ color: '--text-inverse-base' }}
                      >
                        {stat.description}
                      </Paragraph>
                    )}
                  </Stack>
                </Surface>
                {index < statistics.length - 1 && isMobile !== null && (
                  <DividerWrapper>
                    <Divider
                      orientation={isMobile ? 'vertical' : 'horizontal'}
                    />
                  </DividerWrapper>
                )}
              </>
            );
          })}
        </StatContainer>
      </Col>
    </Row>
  );
};

export const Stats = ({
  statistics,
  sizes,
  backgroundColour,
  blockSpacing,
  paddingAdjustment,
}: StatsProps) => {
  return (
    <Section
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
      backgroundColour={backgroundColour?.token}
      className="stats"
    >
      <StatsComponent statistics={statistics} sizes={sizes}></StatsComponent>
    </Section>
  );
};

const StatContainer = styled.div<{ statColor: SurfaceColourTokenType }>`
  border-radius: var(--radius-m);
  background-color: ${({ statColor }) => 'var(' + statColor + ')'};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  ${screen.lg} {
    flex-direction: row;
    text-align: left;
  }
`;

const DividerWrapper = styled.div`
  padding: 0px var(--space-component-xxl);
  ${screen.lg} {
    padding: var(--space-component-xxl) 0px;
  }
  hr {
    background-color: var(--border-inverse-base);
  }
`;

const BoldParagraph = styled(Paragraph)`
  font-weight: normal;

  &:not(:last-child) {
    font-weight: bold;
  }
`;
